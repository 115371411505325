<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container-fluid mt-4">
        <b-alert :show="loading" variant="info">Loading...</b-alert>
        <b-row>
            <b-col>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>License Id</th>
                            <th>Serial Number</th>
                            <th>BLE Mac Address</th>
                            <th>Firmware Version</th>
                            <th>Last seen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="device in devices" :key="device.id">
                            <td>{{ device.licenseId }}</td>
                            <td>{{ device.inventorySerialNumber }}</td>
                            <td>{{ device.bleMacAddress }}</td>
                            <td>{{ device.firmwareVersion }}</td>
                            <td>{{ toLocaleDateTime(device.lastSeen) }}</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { formatDateTimeToLocaleString } from '@/components/utils.js';
    import ApiService from '@/services/api.service';

    export default {
        name: "CustomerDevices",
        data() {
            return {
                loading: false,
                devices: [],
                model: {
                },
            };
        },
        async created() {
            this.refreshDevices();
        },
        computed: {
            ...mapGetters('auth', ['isIntentoSales'])
        },
        methods: {
            async refreshDevices() {
                this.loading = true;
                this.devices = await ApiService.getSiteIntentoProDevices(this.$parent.siteId);
                this.loading = false;
            },
            toLocaleDateTime(d) {
                return formatDateTimeToLocaleString(d);
            }

        }
    };
</script>